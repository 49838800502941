



import {Component, Vue} from 'vue-property-decorator';
import {vxm} from '@/store';

@Component({})
export default class Become extends Vue {
  mounted() {
    if (this.$route.params.token) {
      vxm.user.reset();
      vxm.user.setAuthToken(this.$route.params.token);
      vxm.user
        .getMe()
        .then((res) => {
          if (res.status === 200) {
            if (vxm.user.role === 'user') {
              vxm.user.paymentCards().catch((error) => {
                return error;
              });
            }
            this.$socket.io.opts.query.token = vxm.user.token;
            this.$socket.connect();
            this.$router.push(vxm.user.role === 'user' ? '/fan' : '/model');
          }
        })
        .catch((error) => {
          return error;
        });
    } else {
      this.$router.push('/login');
    }
  }
}
